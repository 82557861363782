import './App.css';
import banner from './assets/bannerA.jpg';
import background from './assets/background.svg';
import messageBG from './assets/messageBG.png';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FormToGoogleSheet from "./components/FormToGoogleSheet";
import React, {useEffect, useState} from "react";
import SVGLoading from "./components/SVGLoading";
import {Box, Container, CssBaseline, Grid} from "@mui/material";
import ScrollDialog from "./components/ScrollDialog";

function App() {
    const [done, setDone] = useState(false);
    const [formStatus, setFormStatus] = useState(false);
    const [load, setLoad] = useState(false)

    const [formReturn, setFormReturn] = useState('')

    useEffect(() => {
        setLoad(true);
        setTimeout(() => setLoad(false), 1000)
    }, [])

    useEffect(() => {
        switch (formStatus) {
            case 'submit':
                setLoad(true);
                break;
            case 'done':
                setLoad(false);
                setDone(true)
                break;
            default:
                break;
        }
    }, [formStatus])

    return (
        <div className="App" style={{backgroundImage: "url(" + background + ")"}}>
            <SVGLoading setLoad={setLoad} load={load}/>
            <img className="banner" src={banner}/>
            {/*<img className="background" src={background}/>*/}
            <Container component="main" maxWidth="lg"
                       sx={{
                           zIndex: 50,
                       }}
            >
                <CssBaseline/>
                {!done && (
                    <Box
                        sx={{
                            marginTop: "3%",
                            marginBottom: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item lg={6} xs={12} sm={12}>
                                <Box
                                    component="div"
                                    className="messageBG"
                                    sx={{
                                        padding: 3,
                                        marginTop: 2,
                                        marginBottom: 2,
                                        height: '97%',
                                        // backgroundColor: "#dee3dd",
                                        backgroundImage: "url(" + messageBG + ")",
                                    }}
                                >
                                    <h2 className="messageHeader">機會嚟喇，飛「魂」！</h2>
                                    <p className="messageContent">
                                        長榮航空喚起你心中想飛的靈魂，誠意賞飛與你踏上旅途！長榮航空有56個環球航點，經台北轉機，前往台灣、日本、泰國、美加、歐洲及澳洲等多個大小城市，長榮帶你飛越長空，放眼世界。
                                    </p>
                                    <p className="messageContent">
                                        參加飛「魂」全世界活動，憑歌寄意講出你最想飛往的長榮航點，有機會贏取機票，立即起程漫遊世界！
                                    </p>
                                    <p className="messageContent">
                                        唱出你的旅行魂！
                                    </p>
                                    <p className="messageContent">
                                        請以一句歌詞表達你最想飛去的長榮航點，可以是真實歌詞或改歌詞（必須注明原曲歌詞），並加以解釋選擇當地原因，最佳答案可以獲得雙人免費機票，讓旅遊夢成真。
                                    </p>
                                    <p className="messageContent">
                                        例子：<br />
                                        一切懷緬的 踏過的 東京都尚好（麗英《東京一轉》）<br />
                                        沒有你 帶香檳 與我私奔 何來浪漫巴黎（魏浚笙《失約巴黎》）<br />
                                    </p>
                                    {/*<p className="messageHeader">*/}
                                    {/*    誰想飛？長榮賞飛！*/}
                                    {/*</p>*/}
                                </Box>
                            </Grid>
                            <Grid item lg={6} xs={12} sm={12}>
                                <FormToGoogleSheet setFormReturn={setFormReturn} setFormStatus={setFormStatus}
                                                   formStatus={formStatus}/>

                                <Container>
                                    <CssBaseline/>
                                    <Box
                                        sx={{
                                            marginTop: 2,
                                            margin: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Grid container sx={{}}>
                                            <Grid item xs={12} sm={12} sx={{
                                                color: '#00622a',
                                            }}>
                                                截止日期：即日起至2024年8月1日 11 時 59 分止（香港時間）<br />
                                                得獎者將會於2024年9月30日前，收到專人通知。
                                            </Grid>
                                        </Grid>

                                        <ScrollDialog />
                                    </Box>
                                </Container>
                            </Grid>
                        </Grid>
                    </Box>
                )}

                {done && (
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            minHeight: "30vh",
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item lg={12} xs={12} sm={12}>
                                <p className="successSubmit">非常感謝你的參與，你已經成功提交參賽作品。我們會挑選出五位最具創意的參賽者，將會獲得長榮航空雙人來回台灣機票。</p>
                                <p className="successSubmit">如果被選為最終的五位幸運優勝者之一，我們將會向你個別進行通知，提供詳細的領獎程序和注意事項。多謝支持，祝你好運！</p>
                            </Grid>
                            <Grid item lg={12} xs={12} sm={12}>
                                <p className="referenceNumber">Reference No.: {formReturn}</p>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Container>
            <footer className="footer">
                <FlightTakeoffIcon
                    size="36"
                    sx={{}}
                />
            </footer>
            {/*<header className="App-header">*/}
            {/*</header>*/}
        </div>
    );
}

export default App;
