import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import SearchIcon from '@mui/icons-material/Search';

const ScrollDialog = (props) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (open) {

        }
    }, [open])

    return (
        <div>
            <Button size="large" onClick={handleClickOpen} sx={{
                color: '#00321d',
            }} startIcon={<SearchIcon />}>
                活動之條款及細則
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title" style={{color:'#006139'}}>活動之條款及細則</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                    >
                        <Grid container spacing={2} sx={{
                            // margin: 2,
                        }}>
                            <Grid item xs={12} sm={12} style={{
                                textAlign: 'justify',
                                color: '#00622a',
                            }}>
                                <ul style={{
                                    listStyleType: 'number',
                                }}>
                                    <li>任何有意參加此活動的人士必須年滿18歲、持有有效之香港身分證，若任何人士未符合以上資格，其參加及/或得獎資格將會被取消。</li>
                                    <li>每位參加者於推廣期內只限參加本活動一次。於推廣期完結後，將從所有符合活動指示要求的參加者中挑選最有創意答案的參加者並獲獎。</li>
                                    <li>EVA AIR 獎品將隨機分發給予得獎者，不能指定任何獎品及要求更換。</li>
                                    <li>活動日期截止日期為2024年8月1日23:59 。</li>
                                    <li>得獎結果將於2024年9月30日前收到專人通知，以電郵通知領取獎品安排。得獎者須根據電郵的指示提供所需的個人資料，及於指定期間親臨指定換領處的地點憑得獎電郵之列印本及其香港身分證領取獎品。在場之工作人員有權核對上述文件，並可全權酌情決定其得獎資格。若得獎者未能於指定期間親臨指定換領處並出示所需文件領取獎品，即表示其放棄領取獎品之權利，並將不會獲補發獎品。</li>
                                    <li>獎品不能兌換現金、不可兑換其他產品、不可退換，亦不可轉讓予第三者。</li>
                                    <li>我們會使用閣下的個人資料作直接促銷、推廣及宣傳我們的特別項目及活動、資訊、特別優惠、服務及禮品。並不會向第三者透露你的個人資料。</li>
                                    <li>任何人士參加者於參加活動之同時，即代表已細閱並同意接受活動之條款及細則約束，如有違 反，EVA AIR 有權取消其參加者參加活動或得獎資格，並對任何破壞活動之行為保留追究權利。</li>
                                    <li>任何因電腦、網路等技術問題而引致參加者所遞交的資料有遲延、遺失、錯誤、無法 辨識等情況，EVA AIR 毋須負上任何責任。</li>
                                    <li>EVA AIR 的員工及直系親屬不能參與本活動，以示公允。</li>
                                    <li>任何人士於參加活動的同時，即代表已細閱並同意接受活動的條款及細則約束，如有違反及任何爭議，EVA AIR 保留最終決定權，包括隨時暫停、更改或終止活動及其條款及細則，而毋須另行通知。</li>
                                    <li>EVA AIR 就得獎者名單、參加活動/得獎資格及提供之獎品等保留最終決定權。參加者於留言所提供之答案正確與否及獲得獎品之資格等，EVA AIR 保留最終決定權。如有任何爭議，EVA AIR保留最終決定權。</li>
                                    <li>EVA AIR有權隨時修改本條款及細則、更改或終止活動。如有任何爭議，EVA AIR 保留最終決定權。</li>
                                </ul>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{
                    textAlign: 'center',
                }}>
                    <Button color="success" variant="contained" sx={{
                        color: 'white',
                    }} onClick={handleClose}>關閉</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ScrollDialog;