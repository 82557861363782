import React, { useState } from 'react';
import {Box, Container, createTheme, CssBaseline, Grid, TextField, ThemeProvider} from "@mui/material";
import Button from "@mui/material/Button";
const FormToGoogleSheet = (props) => {
    const defaultTheme = createTheme();
    let Reference = 'EVAADV2024' + Math.round(1000 + Math.random() * 500) + (new Date().getTime());
    const sheetUrl="https://script.google.com/macros/s/AKfycbxWh5mgYKIHg8DWdyRz4KPA4Cc_QPCdGFqz0sBvnSfbY3AL0hE1BFR6HuNmmd1O8_Hh/exec";

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        Date: (new Date().toString()),
        Reference: Reference,
        return: Reference,
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // console.log(formData);
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        props.setFormStatus('submit');
        setFormData({ ...formData, Date: (new Date().toString())});
        console.log('Form submitted:', formData);

        const data = new FormData();
        data.append('Name', formData.name);
        data.append('Email', formData.email);
        data.append('Phone', formData.phone);
        data.append('Message', formData.message);
        data.append('Date', formData.Date);
        data.append('Reference', formData.Reference);
        data.append('return', formData.return);

        try {
            const response = await fetch(sheetUrl, {
                method: 'POST',
                body: data,
                muteHttpExceptions: true,
            })

            const result = await response.json();
            console.log(result);
            props.setFormReturn(result.return);

            Reference = 'EVAADV2024' + Math.round(1000 + Math.random() * 500) + (new Date().getTime());

            setFormData({
                name: '',
                email: '',
                phone: '',
                message: '',
                Date: (new Date().toString()),
                Reference: Reference,
                return: Reference,
            });

            console.log('done');
        } catch (error) {
            console.log(error);
        }

        props.setFormStatus('done');
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="lg">
                <CssBaseline/>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                type="text"
                                id="name"
                                name="name"
                                label="姓名 (與身份證一致)"
                                variant="outlined"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                sx={{
                                    backgroundColor: 'white',
                                    width:'100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                type="email"
                                id="emal"
                                name="email"
                                label="電郵地址"
                                variant="outlined"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                sx={{
                                    backgroundColor: 'white',
                                    width:'100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                type="phone"
                                id="phone"
                                name="phone"
                                label="聯絡電話"
                                variant="outlined"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                                sx={{
                                    backgroundColor: 'white',
                                    width:'100%'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="message"
                                name="message"
                                label="唱出你的旅行魂！請以一句歌詞表達你最想飛去的長榮航點！"
                                variant="outlined"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                sx={{
                                    backgroundColor: 'white',
                                    width:'100%'
                                }}
                                multiline
                                rows={2}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        variant="contained"
                        type="submit"
                        size="large"
                        color="success"
                        sx={{
                            marginTop: 2,
                            width:'100%'
                        }}
                    >提交</Button>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default FormToGoogleSheet;